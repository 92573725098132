/*
 *  Font faces
 */

@font-face {
  font-family: Rubik;
  src: local(Rubik), url(./fonts/Rubik-Regular.ttf);
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: local(Rubik), url(./fonts/Rubik-RegularItalic.ttf);
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: local(Rubik), url(./fonts/Rubik-Bold.ttf);
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: local(Rubik), url(./fonts/Rubik-BoldItalic.ttf);
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: local(Rubik), url(./fonts/Rubik-Light.ttf);
  font-weight: lighter;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: local(Rubik), url(./fonts/Rubik-LightItalic.ttf);
  font-weight: lighter;
  font-style: italic;
  font-display: swap;
}

/*
 *  Everything else
 */

body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #051a26;
  color: #cfe0f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
